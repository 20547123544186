<template>
  <div class="">
    <div class="pageTitle b-b-1e Pb12">
      <strong class="tit">完善个人信息</strong>
    </div>
    <div>
      <div class="ptBx2 formTopJG">
        <div class="pnimg">
          <div class="fxcBox">
            <el-upload
              class="avatar-uploader usPhoto"
              action=""
              :show-file-list="false"
              :before-upload="uploadsA"
            >
              <template v-if="usPhoto">
                <img
                  :src="usPhoto"
                  class="avatar"
                  style="width:100%;height:100%"
                />
              </template>
              <template v-else>
                <div><i class="el-icon-plus"></i></div>
                <p>上传头像</p>
              </template>
            </el-upload>
          </div>
        </div>
        <div class="ptcont ">
          <div class="form">
            <el-form
              ref="ruleFormRef"
              :model="ruleForm"
              :rules="rules"
              label-width="100px"
              class="usHome-ruleForm"
              :size="formSize"
            >
              <el-form-item label="用户昵称" prop="name">
                <el-input v-model="ruleForm.name" />
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="ruleForm.email" />
              </el-form-item>

              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                  <el-radio :label="3">保密</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="学历">
                <el-select v-model="ruleForm.diploma" placeholder="请选择">
                  <el-option label="本科" value="本科" />
                  <el-option label="专科" value="专科" />
                  <el-option label="硕士" value="硕士" />
                  <el-option label="博士" value="博士" />
                  <el-option label="高中" value="高中" />
                  <el-option label="初中" value="初中" />
                </el-select>
              </el-form-item>

              <el-form-item label="毕业院校">
                <el-input
                  v-model="ruleForm.school"
                  placeholder="请输入信息"
                ></el-input>
              </el-form-item>

              <el-form-item label="职称">
                <el-input
                  v-model="ruleForm.qualifications"
                  placeholder="请输入信息"
                ></el-input>
              </el-form-item>
              <el-form-item label="籍贯">
                <el-input
                  v-model="ruleForm.nativePlace"
                  placeholder="请输入信息"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在城市">
                <el-input
                  v-model="ruleForm.myCity"
                  placeholder="请输入信息"
                ></el-input>
              </el-form-item>

              <el-form-item label="擅长领域 ">
                <el-checkbox-group v-model="goodField" @change="aaaa">
                  <el-checkbox
                    v-for="(iam, iax) in hangye"
                    :key="iax"
                    :label="iam.fieldLabel"
                    :value="iam.fieldValue"
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="submitForm(ruleFormRef)"
                  style="min-width:120px"
                >
                  提交
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getPersonalInformation, uploadImg, getUsInformation } from '@/api/user'
import { dictionary } from '@/api/sys'
import { sign, setItem, getItem } from '@/utils/storage'
import tools from '@/utils/tools'
import { useRouter } from 'vue-router'
const router = useRouter()

const hangye = ref([])
const hangyeFun = async () => {
  hangye.value = await dictionary(sign({ type: 'field' }))
}
hangyeFun() // 行业
const yziliao = async () => {
  console.log('获取个人资料')
  // var sjs = Math.ceil(Math.random() * 10)
  var usxx = await getUsInformation(sign({ userinfo: 'sjs' }))
  if (usxx !== 'no') {
    ruleForm.value = {
      name: getItem('userinfo').name, // 昵称
      sex: parseInt(getItem('userinfo').sex), // 1男2女3保密
      email: usxx.email, //
      photo:
        getItem('userinfo').photo === null ? '' : getItem('userinfo').photo, // 头像
      diploma: usxx.diploma === null ? '' : usxx.diploma, // 学历
      school: usxx.school === null ? '' : usxx.school, // 毕业学校
      qualifications: usxx.qualifications === null ? '' : usxx.qualifications, // 职称
      nativePlace: usxx.nativePlace === null ? '' : usxx.nativePlace, // 籍贯
      myCity: usxx.myCity === null ? '' : usxx.myCity, // 所在城市
      goodField: usxx.goodField === null ? '' : usxx.goodField // 擅长领域
    }
    if (getItem('userinfo').photo !== null) {
      usPhoto.value = window.g.baseURL + getItem('userinfo').photo
    }
    goodField.value = usxx.goodField.split(',')
  }
}
yziliao()

const goodField = ref([])
const aaaa = aa => {
  ruleForm.value.goodField = aa.join(',')
}

const formSize = ref('default')
const usPhoto = ref('')

const ruleFormRef = ref()
const ruleForm = ref({
  name: '', // 昵称
  sex: 3, // 1男2女3保密
  email: '', //
  photo: '', // 头像
  diploma: '', // 学历
  school: '', // 毕业学校
  qualifications: '', // 职称
  nativePlace: '', // 籍贯
  myCity: '', // 所在城市
  goodField: '' // 擅长领域
})

const rules = {
  name: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { min: 2, max: 40, message: '2-40个字符', trigger: 'blur' }
  ],
  email: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { type: 'email', message: '格式不正确', trigger: 'blur' }
  ],
  sex: [
    {
      required: true,
      message: '请选择性别',
      trigger: 'change'
    }
  ]
}
// 提交
const submitForm = async formEl => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      tools.basic.filterEmptyData(ruleForm.value)
      postCertificationFun(ruleForm.value)
    } else {
      console.log('提交失败', fields)
    }
  })
}
const postCertificationFun = async data => {
  var revv = await getPersonalInformation(sign(data))

  if (revv.zt === 'ok') {
    setItem('userinfo', revv.userinfo)
    router.push('/usindex')
  }
}

// 上传文件
const uploadsA = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  ruleForm.value.photo = revv
  usPhoto.value = window.g.baseURL + revv
}

// const goPage = ss => {
//   router.push(ss)
// }
</script>
<style lang="scss" scoped></style>
